/**
 * Payment methods for NetAxept are listed here to support the bank selection in MyRopo.
 * The objects contain the following:
 * - name of the bank, displayed to the user
 * - bank_id, not displayed to the user, used in the query parameter and passed to the service provider (to redirect the user to the bank). bank_id's are also used as env.variable REACT_APP_NETAXEPT_PRESELECT_BANKS values.
 * - logo of the bank, displayed to the user
 * - rank can be used to alter the order in which the bank options are displayed (col order)
 * 
 * If Tink is used as the payment service (REACT_APP_PAYMENT_SERVICE),
 * additional banks from Nets can be added with bankids in variable REACT_APP_NETAXEPT_PRESELECT_BANKS.
 */

// Available Netaxept payment methods
export const netaxeptPreselectBanks = {
    providers: [
        {
            name: "Aktia",
            bank_id: "FinishBankAktia",
            logo: "/img/aktia.png",
            rank: 0
        },
        {
            name: "Danske Bank",
            bank_id: "FinishBankSampo",
            logo: "/img/danske-bank.png",
            rank: 0
        },
        {
            name: "Handelsbanken",
            bank_id: "FinishBankHandelsbanken",
            logo: "/img/handelsbanken.png",
            rank: 0
        },
        {
            name: "Nordea",
            bank_id: "FinishBankNordea",
            logo: "/img/nordea.png",
            rank: 0
        },
        {
            name: "Oma Säästöpankki",
            bank_id: "FinishBankOmaSaastopankki",
            logo: "/img/oma-sp.png",
            rank: 0
        },
        {
            name: "Osuuspankki",
            bank_id: "OPBankFinland",
            logo: "/img/osuuspankki.png",
            rank: 0
        },
        {
            name: "POP Pankki",
            bank_id: "FinishBankPopPankki",
            logo: "/img/pop.png",
            rank: 0
        },
        {
            name: "S-Pankki",
            bank_id: "FinishBankSPankki",
            logo: "/img/spankki.png",
            rank: 0
        },
        {
            name: "Säästöpankki",
            bank_id: "FinishBankSaastopankki",
            logo: "/img/saastopankki.png",
            rank: 0
        },
        {
            name: "Ålandsbanken",
            bank_id: "FinishBankAlandsbanken",
            logo: "/img/alandsbanken.png",
            rank: 0
        },
        {
            name: "Swish",
            bank_id: "SwishE",
            logo: "/img/swish.png",
            rank: 0
        },
        {
            name: "Vipps",
            bank_id: "Vipps",
            logo: "/img/vipps.png",
            rank: 0
        }
    ]
};

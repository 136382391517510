import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Col, Row, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { injectIntl } from "react-intl";
import axios from "axios";
import Moment from "moment";
import isEmpty from 'lodash.isempty';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { messages, prohibited } from './messages';
import {messages as dashBoardMessages} from '../Dashboard/index';

// services
import { userLogin, userLoggedin, userGetToken, userGetName, userRequestHeaders, userIsAuthorized, userGetLocale } from '../../services/user/index';
import { jobGetCode, jobGetJob, jobFetchJob, JOB_ENERGYTYPE, jobGetGovidJob } from '../../services/job/index';
import { paymentService } from '../../services/payment/index';
import { toggleLoading, changeLoadingText, formatMoney, mapLocaleToHeaders } from "../../services/helpers/index";
import { role } from '../../services/helpers/role';
import { messagecenterGetMessages, messagecenterRemoveMessages } from "../../services/messagecenter";

// cards
import SubJobs from './components/Cards/SubJobs.js';
import StatusInfo from './components/Cards/StatusInfo.js';
import InvoiceShortOverview from './components/Cards/InvoiceShortOverview.js';
import PaymentDetails from './components/Cards/PaymentDetails.js';
import BankInformation from './components/Cards/BankInformation.js';
import ServingCard from './components/Cards/ServingCard.js';
import InvoiceOverview from './components/Cards/InvoiceOverview.js';

// modals
import ServingModal from './components/Modals/ServingModal.js';
import BankSelectionModal from './components/Modals/BankSelectionModal';
import PayplanModal from './components/Modals/PayplanModal.js';
import FiresafetyModal from './components/Modals/FiresafetyModal.js';
import NewPaydateModal from "./components/Modals/NewPaydateModal";
import MandateModal from './components/Modals/MandateModal.js';
import MessageCenterModal from './components/Modals/MessageCenterModal.js';

// components
import AlertDialog from './../../components/AlertDialog/index.js';
import ToggleChatButton from "../../components/ToggleChatButton";
import OverpaidWizard from "../../components/OverpaidWizard";
import MessageCard from './../MessageCenter/components/MessageCard';

import { netaxeptPreselectBanks } from './paymentProviders';

const Job = (props) => {

	// store dynamically changing and added variables
	const [state, setState] = useState({
		paymentcount: 2,
		paymentinterval: '4w',
	});

	const lng = props.intl.formatMessage;
	const [alertDialogProperties, setAlertDialogProperties] = useState({
		show: false,
		message: '',
		type: '',
	});

	const isAuthed = userIsAuthorized(role.authenticated) && jobGetCode() !== null;
	const [showAlert, setShowAlert] = useState(false);
	const [showAlertMessage, setShowAlertMessage] = useState('');
	const [showAlertType, setShowAlertType] = useState(undefined);
	const [isAuthenticating, setIsAuthenticating] = useState(true);
	const [job, setJob] = useState(null);
	const [isServingEligible, setIsServingEligible] = useState(false);
	const [showServingCard, setShowServingCard] = useState(false);
	const [servingSigned, setServingSigned] = useState(false);
	const [servingSignedDate, setServingSignedDate] = useState("");
	const [disableServingButton, setDisableServingButton] = useState(false);
	const [toggleNewPaydateModal, setToggleNewPaydateModal] = useState(false);
	const [togglePayplanModal, setTogglePayplanModal] = useState(false);
	const [currency, setCurrency] = useState(process.env.REACT_APP_DEFAULT_CURRENCY);
	const [disablePayButton, setDisablePayButton] = useState(false);
	const [toggleServingModal, setToggleServingModal] = useState(false);
	const [toggleFiresafetyModal, setToggleFiresafetyModal] = useState(false);
	const [toggleBankSelectionModal, setToggleBankSelectionModal] = useState(false);
	const [toggleMandateModal, setToggleMandateModal] = useState(false);
	const [toggleMessageCenterModal, setToggleMessageCenterModal] = useState(false);
	const [clearMessageCenterModal, setClearMessageCenterModal] = useState(false);
	const [serviceProvider, setServiceProvider] = useState(paymentService.paymentService);
	// toggle job details in mobile view
	const [showAllInvoiceDetailsMobile, setShowAllInvoiceDetailsMobile] = useState(false);
	const [currentIndex, setCurrentIndex] = useState(-1);
	const [currentPage, setCurrentPage] = useState(-1);
	const [lastPage, setLastPage] = useState(-1);
	const [showBrowseButton, setShowBrowseButton] = useState(false);
	const [firstInGovidJobs, setFirstInGovidJobs] = useState(false);
	const [lastInGovidJobs, setLastInGovidJobs] = useState(false);
	const supportedServices = Object.values(paymentService.PAYMENTMETHODS);
	const netaxeptBankIds = netaxeptPreselectBanks.providers.map(obj => obj.bank_id);

	// Wizard
	const [wizardStep, setWizardStep] = useState(-1);
	const [showWizardButton, setShowWizardButton] = useState(false);
	const [isDirectLinkOverpaid, setIsDirectLinkOverpaid] = useState(false);
	const [toggleWizard, setToggleWizard] = useState(false);
	const [overpaidInformation, setOverpaidInformation] = useState([]);
	const [showOverpaidInformation, setShowOverpaidInformation] = useState(false);

	const [genericMessage, setGenericMessage] = useState("");
    
	// Chat
	const [livechatParameters, setLivechatParameters] = useState([]);

	// Take alert-content from location-prop
	const { message, type } = (props.location?.alert) || {};

	// Message Center
	const [showNewMessageButton, setShowNewMessageButtons] = useState(true);
	const [allMessages, setAllMessages] = useState([]);

	let allowServing = false;

	// check if serving is allowed in the environment
	if (typeof process.env.REACT_APP_SHOW_SERVING !== 'undefined' && process.env.REACT_APP_SHOW_SERVING.toString() === '1') {
		allowServing = true;
	}

	let preselectPayment = false;

	// check if bank selection modal can be shown in the environment (N.B. can be overridden if REACT_APP_NETAXEPT_PRESELECT_BANKS contains banks)
	if (typeof process.env.REACT_APP_PRESELECT_PAYMENT !== 'undefined' && process.env.REACT_APP_PRESELECT_PAYMENT.toString() === '1') {
		preselectPayment = true;
	}

	let includedNetsBanks = [];
	let redirectToTinkForPayment = false;

	// check if there are any banks from Netaxept that should be included in the payment method selection
	if (typeof process.env.REACT_APP_NETAXEPT_PRESELECT_BANKS !== 'undefined' && !isEmpty(process.env.REACT_APP_NETAXEPT_PRESELECT_BANKS)) {
		includedNetsBanks = process.env.REACT_APP_NETAXEPT_PRESELECT_BANKS.replace(' ', '').split(',');

		// check if visibility of the bank selection modal needs to be changed
		if (!preselectPayment && serviceProvider === paymentService.PAYMENTMETHODS.TINK) {
			// override preselect payment modal setting from false to true, if Netaxept banks have been defined in an environment where bank selection modal is disabled (user chooses between Netaxept banks (redirects to chosen bank in Nets) or 'Online banks' (redirected to Tink))
			preselectPayment = true;
			redirectToTinkForPayment = true; // redirect to Tink for payment method selection instead of showing the banks in MyRopo
		}
	}

	let showFiresafety = true;

	// check if firesafety is set and show firesafety if value is 1
	if (typeof process.env.REACT_APP_CONFIRM_FIRESAFETY !== 'undefined' && process.env.REACT_APP_CONFIRM_FIRESAFETY.toString() === '0') {
		showFiresafety = false;
	}

	let paymentlimit = 0;

	// check if there is an allowed maximum amount for an invoice's open amount that can still be paid in MyRopo
	if (typeof process.env.REACT_APP_PAYMENT_LIMIT !== 'undefined' && process.env.REACT_APP_PAYMENT_LIMIT > 0) {
		paymentlimit = parseFloat(process.env.REACT_APP_PAYMENT_LIMIT);
	}

	const showChatButton = typeof process.env.REACT_APP_SHOW_CHAT !== 'undefined' && process.env.REACT_APP_SHOW_CHAT.toString() === '1';
	const showMessageCenterButton = typeof process.env.REACT_APP_MESSAGECENTER !== 'undefined' && process.env.REACT_APP_MESSAGECENTER.toString() === '1';

	let showSupportSection = false;

	// Get support section should be shown, if either the chat or message center is available
	if (showChatButton || showMessageCenterButton) {
		showSupportSection = true;
	}

	let messageCenterAuthorized = false;

	// check if user is authorized to use the message center
	if (showMessageCenterButton && isAuthed) {
		messageCenterAuthorized = true;
	}

	// Fetch initial messages when component mounts
	const fetchInitialMessages = async () => {

		if (job?.jobid) {
			let messages = await messagecenterGetMessages(job.jobid.toString());

			setAllMessages(messages);

			if (messages?.length > 0) {
				let isClosed = true;

				isClosed = messages.every((message) => {
					if (message.current_status?.status !== 'Closed') {
						return false; // break loop 
					}
					return true; // continue loop
				});
				setShowNewMessageButtons(isClosed);
			} else {
				setShowNewMessageButtons(true);
			}
		}
	}

	useEffect(() => {
		// Loading animation
		toggleLoading(true, lng(messages.loading) + '...');

		// Show alerts that are being given by previous view
		if (message !== undefined) {
			handleAlertDialog(message, type);
		}

		if (props.match.params.uuid) {
			userLogin(props.match.params.uuid)
				.then((res) => {
					changeLoadingText(lng(messages.searchingForInvoice));
					jobFetchJob()
						.then(function (response) {
							const job = jobGetJob();

							// Sets a possible new paydate already
							job.newpaydate = addDaysToToday(job.paydate, 14);
							job.firstpaydate = Moment(job.paydate).format('YYYY-MM-DD');

							if (Moment(job.paydate).format('x') < Moment().format('x')) {
								job.firstpaydate = Moment().format('YYYY-MM-DD');
							}

							const jobCurrency = get(job, 'currency', false);

							// Set currency if set on the job data
							if (jobCurrency && jobCurrency !== '') {
								setCurrency(jobCurrency);
							}

							// sets job's details
							setJob(job);

							// Show wizard if job status is 302 or 104 and account number is not yet given
							if (job.statuscode === 302 || job.statuscode === 104) {
								// Check if bank account number has already been notified
								if (job.modoverpaid !== null && job.modoverpaid) {
									// If IBAN has not been notified, open wizard invoice data step
									setWizardStep(0);
									setShowWizardButton(true);
								} else {
									setWizardStep(-1);
									setShowWizardButton(false);
								}
								setIsDirectLinkOverpaid(true);
							}

							// Forced payment service exists?
							const service = props.match?.params?.paymentmethod;
							const supportedServices = Object.values(paymentService.PAYMENTMETHODS);

							if (supportedServices.includes(service)) {
								window.sessionStorage.setItem('paymentService', service);
							} else if (window.sessionStorage.getItem('paymentService') !== null) {
								// If there is no payment service in the url, but session storage
								// value exists, remove values from the session storage
								window.sessionStorage.removeItem('paymentService');
							}
						})
						.catch(function (error) {
							toggleLoading(false);
							if (error.response?.status === 401) {
								const alert = {message: lng(messages.authError), type: 'danger'};
								props.history.push({
									pathname: "/logout",
									alert
								});
							} else {
								// Failed request
								const alert = { message: lng(messages.somethingWentWrong), type: 'danger' };
								props.history.push({
									pathname: "/",
									alert
								});
							}

						});

						// Update user logged status
						if (props.updateUserLogged) {
							props.updateUserLogged(true);
						}
						
				}, (error) => {
					toggleLoading(false);
					if (error.response?.status === 401) {
						const alert = {message: lng(messages.authError), type: 'danger'};
						props.history.push({
							pathname: "/logout",
							alert
						});
					} else {
						// Failed request
						const alert = { message: lng(messages.authError), type: 'danger' };
						props.history.push({
							pathname: "/",
							alert
						});
					}
				});
		} else if (userLoggedin() === false) {
			const alert = { message: lng(messages.authRequired), type: 'primary' };
			props.history.push({
				pathname: "/",
				alert
			});

		} else if (jobGetJob() === null) {
			const alert = { message: lng(messages.retrySearch), type: 'primary' };
			props.history.push({
				pathname: "/jobsearch",
				alert
			});

		} else {
			const job = jobGetJob();

			// Sets a possible new paydate already
			job.newpaydate = addDaysToToday(job.paydate, 14);
			job.firstpaydate = Moment(job.paydate).format('YYYY-MM-DD');

			if (Moment(job.paydate).format('x') < Moment().format('x')) {
				job.firstpaydate = Moment().format('YYYY-MM-DD');
			}

			const jobCurrency = get(job, 'currency', false);

			// Set currency if set on the job data
			if (jobCurrency && jobCurrency !== '') {
				setCurrency(jobCurrency);
			}

			setJob(job);
		}

		window.scrollTo(0, 0);

	}, []);


	// When job is updated, perform other related actions
	useEffect(() => {

		if (!isEmpty(job)) {
            // Check for serving
            checkForServing();
           
			// sets parameters for LiveChat
			setLivechatParameters([
				{ name: "jobid", value: job.jobid },
				{ name: "token", value: userGetToken() }
			])

			// check if browse button between jobs should be shown
			if (jobGetGovidJob() !== null) {
				browseButtonsDetails();
			}

			if (paymentlimit > 0 && job.openamount > paymentlimit) {
				setDisablePayButton(true);
			} else {
				setDisablePayButton(false);
			}

			if (props.maintenance) {
				handleAlertDialog(lng(messages.underMaintenance), 'warning');
			} else if (messageCenterAuthorized) {
				fetchInitialMessages();
			}
		}

	}, [job, props.maintenance]);

	/**
	 * Add details to browse buttons: current page and number of pages altogether.
	 * Also keep track of the index of the current job.
	 */
	function browseButtonsDetails() {
		setFirstInGovidJobs(false);
		setLastInGovidJobs(false);
		const govidJobs = jobGetGovidJob();
		let currenctIndex = 0;

		// check the index of currently open job, returns -1 if no matching dpid is found
		currenctIndex = govidJobs.findIndex((govidJob) => govidJob.dpid === job.dpid);

		// browse button should not be visible if job is not in the govid job listing
		if (currenctIndex > -1) {
			// set page numbers and index and save current index
			setCurrentPage(currenctIndex + 1);
			setLastPage(govidJobs.length);
			setCurrentIndex(currenctIndex);

			// check if job is first or last (affets e.g. button styling)
			if (currenctIndex === 0) {
				setFirstInGovidJobs(true);
			}
			if (currenctIndex === govidJobs.length - 1) {
				setLastInGovidJobs(true);
			}

			// show button for browsing
			setShowBrowseButton(true);
		}
	}
    
    

	function handleOverpaidInfo(wizardOverpaid) {

		if (wizardOverpaid.show) {
			setOverpaidInformation(wizardOverpaid);
			setShowOverpaidInformation(true);
		}
	}

	function handleOverpaidMessage(overpaidMessage) {
		setGenericMessage(overpaidMessage);
	}

	/**
	 * Get the previous job from govidjob list.
	 */
	function getPreviousJob() {
		if (currentIndex > 0) {
			const prevDpid = currentIndex - 1;
			const previousJob = jobGetGovidJob()[prevDpid].dpid;

			handleBrowseJob(previousJob);
		}
	}

	/**
	 * Get the next job from govidjob list.
	 */
	function getNextJob() {
		const govidJobs = jobGetGovidJob();

		if (currentIndex < govidJobs.length - 1) {
			const nextDpid = currentIndex + 1;
			const nextJob = govidJobs[nextDpid].dpid;

			handleBrowseJob(nextJob);
		}
	}

	/**
	 * Browse jobs by fetching the previous or next job from listed govid jobs with dpid.
	 * @param {*} dpid
	 */
	function handleBrowseJob(dpid) {
		toggleLoading(true, lng(messages.searchingForInvoice));
		setShowAlert(false); // hide any remaining error messages

		// fetch previous or next job based on the dpid
		jobFetchJob(dpid).then(function () {
			toggleLoading(false);
			const newJob = jobGetJob();

			// Sets a possible new paydate already
			newJob.newpaydate = addDaysToToday(newJob.paydate, 14);
			newJob.firstpaydate = Moment(newJob.paydate).format('YYYY-MM-DD');

			if (Moment(newJob.paydate).format('x') < Moment().format('x')) {
				newJob.firstpaydate = Moment().format('YYYY-MM-DD');
			}

			const jobCurrency = get(newJob, 'currency', false);

			// Set currency if set on the job data
			if (jobCurrency && jobCurrency !== '') {
				setCurrency(jobCurrency);
			}

			setJob(newJob);

		}).catch(function (error) {
			toggleLoading(false);
			if (typeof error?.response !== 'undefined') {
				if (error.response.status === 401) {
					// authentication failed, redirect back to authentication
					const alert = { message: lng(messages.authError), type: 'danger' };
					props.history.push({
						pathname: "/logout",
						alert
					});
				}
			} else {
				// request failed completely
				handleAlertDialog(lng(messages.somethingWentWrongTryAgain), 'danger');
			}
		});
	}

	/**
	 * Helper function which formats a given amount to the current locale with the right currency
	 *
	 * @param amount - Sum
	 * @returns string The formatted sum including currency symbol
	 */
	function parseAmount(amount) {
		return formatMoney(amount, userGetLocale(), currency);
	}

	/**
	 * Handle alertDialog. Function takes message and type as parameters
	 * and show the alertdialog above all the details related to the invoice.
	 *
	 * @param {*} message
	 * @param {*} type
	 */
	function handleAlertDialog(message, type) {
		setShowAlertMessage(message);
		setShowAlertType(type);

		//show alertDialog
		setShowAlert(true);
	}

	/**
	 * Opens the modal for changing the paydate
	 */
	function showNewPaydateModal() {
		if (typeof job.modpaydate !== "undefined" && job.modpaydate) {
			setToggleNewPaydateModal(!toggleNewPaydateModal);
		}
	}

	/**
	 * Open the serving modal.
	 */
	function showServingModal() {
		if (!disableServingButton) {
			setToggleServingModal(!toggleServingModal);
		}
	}

	/**
	 * Check if requirements for serving are met. Also check
	 * - that the enviroment allows it,
	 * - that job is eligible for serving,
	 * - if serving is signed or not
	 * - that user has used strong authentication.
	 * If response status is 200 and served_at is undefined and serving_type is letter serving needs signing
	 */
	function checkForServing() {

		// check serving when environment variable allows it and there is serving on job (job.hasserving = true), otherwise proceed normally
		if (allowServing && job.hasserving) {
			const headers = userRequestHeaders();
			const dpid = jobGetCode();

			axios({
				method: 'GET',
				url: process.env.REACT_APP_API_URL + '/online/serving/' + dpid,
				headers: headers,
			})
				.then(function (res) {
					toggleLoading(false);

					// allow the view to be rendered
					setIsAuthenticating(false);

					if (res.status === 200 && typeof res?.data?.data.served_at === 'undefined' && res?.data?.data.serving_type === 'letter') {
						setIsServingEligible(true);
						// show panel and button
						setShowServingCard(true);
						setServingSigned(false);
					} else if (res.status === 204) {
						setIsServingEligible(false);
						setShowServingCard(false);
					} else if (typeof res?.data.data.served_at !== 'undefined') {
						// a signature for a serving is found, show details in serving section
						setIsServingEligible(true);
						setServingSigned(true);
						// format the date of the signature
						const date = res.data.data.served_at.split(' ');
						setServingSignedDate(date[0]);
						setShowServingCard(true);
					}
				})
				.catch(function (error) {
					toggleLoading(false);

					// allows the view to be rendered
					setIsAuthenticating(false);

					if (noServingResponse404(error.response)) {
						setIsServingEligible(false);
						setShowServingCard(false);
					} else if (error.response?.data.title === "Invalid UserRequest") {
						// signing a serving is not allowed if user is not logged in (requires strong authentication)
						handleAlertDialog(lng(messages.authErrorShort) + ". " + lng(messages.strongAuthenticationNeeded), 'danger');

						// don't allow signing, disable serving button
						setShowServingCard(true);
						setServingSigned(false);
						setIsServingEligible(true);
						setDisableServingButton(true);

					} else if (error.response?.status === 401) {
						// authentication failed, redirect back to authentication
						const alert = { message: lng(messages.authError), type: 'danger' };
						props.history.push({
							pathname: "/logout",
							alert
						});
					} else {
						// request failed
						handleAlertDialog(lng(messages.errorOccurred) + ". " + lng(messages.somethingWentWrongTryAgain), 'danger');
						// don't allow signing if request fails
						setShowServingCard(false);
						setServingSigned(false);
					}
				});
		} else {
			toggleLoading(false);

			// allows to view to be rendered
			setIsAuthenticating(false);
		}
	}

	/**
	 * Updates state when there are changes in the inputs
	 *
	 * @param event
	 */
	function updateState(event) {
		// Checks if a value is given -> not empty, or a class is removed
		if (event.target.value) {
			event.target.classList.add('not-empty');
		}
		else {
			event.target.classList.remove('not-empty');
		}

		// modify existing or create new variables
		setState({ ...state, [event.target.id]: event.target.value })
	}

	/**
	 * Sets the first paydate for a payment plan
	 * @param date
	 */
	function handleFirstPaydateChange(date) {
		const job = jobGetJob();
		job.firstpaydate = Moment(date).format('YYYY-MM-DD');

		// Updates the first paydate to state
		setJob(job);
	}

	/**
	 * No serving if status is 404 with specific messages
	 * 404 when no match found, no changes to UI
	 *
	 * @param response
	 * @returns
	 */
	function noServingResponse404(response) {
		return response?.status === 404 && (
			response?.data.title === 'No serving found' ||
			response?.data.title === 'Address data not found, serving not allowed'
		);
	}

	/**
	 *  Save user's signature for a serving.
	 *  Signing is possible only with strong authentication.
	 */
	function signServing(e) {
		e.preventDefault();
		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();
		const dpid = jobGetCode();

		axios({
			method: 'POST',
			url: process.env.REACT_APP_API_URL + '/online/signserving',
			headers: headers,
			data: {
				id: dpid,
				serving_type: 'online'
			}
		})
			.then(function (res) {
				if (res.status === 201) {
					// show success to a user in a dialog
					handleAlertDialog(lng(messages.titleThankYou) + ". " + lng(messages.changesSaved), 'success');

					// signature is saved, show details in serving section
					const date = res.data.data.served_at.split(' ');
					setServingSignedDate(date[0]);
					setShowServingCard(true);

					// close signing dialog
					setToggleServingModal(false);
					setServingSigned(true);
					// loading animation off
					toggleLoading(false);
				}
			})
			.catch(function (error) {
				toggleLoading(false);

				// close signing dialog
				setToggleServingModal(false);

				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
				} else if (error.response?.data.title === "Invalid UserRequest") {
					// signing a serving is not allowed if user is not logged in (requires strong authentication)
					handleAlertDialog(lng(messages.authErrorShort) + ". " + lng(messages.strongAuthenticationNeeded), 'danger');

					// don't allow signing if invalid userrequest, disable serving button
					setServingSigned(false);
					setDisableServingButton(true);
				} else if (noServingResponse404(error.response)) {
					// don't allow signing
					setServingSigned(false);
					setDisableServingButton(true);
				} else {
					// request failed, show alertdialog
					handleAlertDialog(lng(messages.titleSavingFailed) + ". " + lng(messages.somethingWentWrongTryAgain), 'danger');
				}
			});
	}

	/**
	 * Sets a new paydate
	 * @param date
	 */
	function handleNewPaydateChange(date) {
		// replaces the job.newpaydate value set in useEffect with the datepicker value
		const job = jobGetJob();
		job.newpaydate = Moment(date).format('YYYY-MM-DD');
		setJob(job);
	}

	/**
	 * Submits the changes in the paydate
	 */
	function submitNewPaydate(e) {
		e.preventDefault();

		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();
		const dpid = jobGetCode();

		// HTTP Request
		axios({
			method: 'PATCH',
			url: process.env.REACT_APP_API_URL + '/online',
			headers: headers,
			data: JSON.stringify({
				id: dpid,
				dataset: {
					paydate: job.newpaydate
				}
			})
		})
			.then(function (response) {

				let payDateMovedToWorkday = false;

				// checks whether or not the response indicates that paydate had been moved to workday
				if (response.data.message === "Duedate moved to workday") {
					payDateMovedToWorkday = true;
				}

				// A successful request, gets the job's data again
				jobFetchJob(dpid).then(function (response) {
					// hides the modal
					setToggleNewPaydateModal(false);
					// updates the state
					setJob(response);

					// if notification about paydate change was received in the patch response, show dialog with information about it to user, else show OK dialog
					if (payDateMovedToWorkday) {
						// dialog to inform the user that the paydate has been moved to the next available workday
						handleAlertDialog(lng(messages.payDateMovedInfoMessage), 'success');
					}
					else {
						// OK dialog
						handleAlertDialog(lng(messages.changesSaved), 'success');
					}
				});
			})
			.catch(function (error) {
				toggleLoading(false);
				// hides the modal
				setToggleNewPaydateModal(false);

				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
				} else {
					// Error is shown in the alertDialog
					showAlertDialog({
						message: lng(error.response?.status === 403 ? messages.changingPaydateNotAllowed : messages.pleaseTryAgain),
						type: 'danger',
						show: true
					});
				}

			});
	}

	/**
	 * Adds the given amount of days to a date and returns a new date
	 *
	 * @param date
	 * @param days
	 * @returns {moment.Moment}
	 */
	function addDaysToToday(date, days) {
		date = Moment(date, 'YYYY-MM-DD').add(days, 'days');
		return date.format('YYYY-MM-DD');
	}

	/**
	 * Opens the dialog for creating a new payment plan
	 */
	function showPayplanModal() {
		setTogglePayplanModal(!togglePayplanModal);
	}

	function showAlertDialog(obj) {
		const {message, type, show} = obj;

		let dialogProperties = {...alertDialogProperties};
		dialogProperties.message = message;
		dialogProperties.type = type;
		dialogProperties.show = show;

		setAlertDialogProperties(dialogProperties);
	}

	/**
	 * Saves a payment plan
	 */
	function submitPayplan(e) {
		e.preventDefault();

		// Shows the loading animation "Saving..."
		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();
		const dpid = jobGetCode();

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL + '/online/payplan',
			headers: headers,
			data: JSON.stringify({
				id: dpid,
				firstpaydate: job.firstpaydate,
				count: state.paymentcount,
				interval: state.paymentinterval
			})
		})
			.then(function (response) {

				let payDateMovedToWorkday = false;
				// checks whether or not the response indicates that paydate had been moved to workday
				if (response.data.message === "Duedate moved to workday") {
					payDateMovedToWorkday = true;
				}

				// A successful request, gets the job's data again
				jobFetchJob(dpid).then(function (response) {
					showPayplanModal();

					// Updates state
					setJob(response);

					// if notification about paydate change was received in the response, show dialog with information about it to user, else show OK dialog
					if (payDateMovedToWorkday) {
						let message = lng(messages.payDateMovedInfoMessage) + '\n' + lng(messages.payplanSent);
						// dialog to inform the user that the paydate has been moved to the next available workday
						handleAlertDialog(message, 'success');
					} else {
						// OK dialog
						handleAlertDialog(lng(messages.payplanSent), 'success');
					}
				});
			})
			.catch(function (error) {
				let message = messages.pleaseTryAgain;

				// If a request has a response, it is handled here
				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
				} else if (typeof error.response !== "undefined") {
					if (error.response.status === 403) {
						message = messages.creatingPayplanNotAllowed;
					} else if (error.response.data.Message === "Payplan is too long") {
						message = messages.payplanTooLong;
					}
				}

				toggleLoading(false);
				showAlertDialog({
					message: lng(message),
					type: 'danger',
					show: true
				});
			});
	}

	/**
	 * Toggle visibility of firesafety modal.
	 */
	function showFireSafetyModal() {
		setToggleFiresafetyModal(!toggleFiresafetyModal);
	}

	/**
	 * Check if firesafety modal should be shown before the user can pay.
	 * Firesafety information should be asked for jobs with statuscode 156 and energtytype=electricity or null
	 */
	function checkFiresafetyBeforePaying() {
		// check if firesafety information should be asked
		if (job.statuscode === 156 && showFiresafety && (job.energytype === JOB_ENERGYTYPE.ELECTRICITY || isEmpty(job.energytype))) {
			showFireSafetyModal();
		} else {
			makePaymentOrShowBankModal();
		}
	}

	/**
	 * Firesafety data is sent to the job before paying
	 * @param phoneNumber
	 */
	function submitFireSafety(phoneNumber) {
		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();
		const dpid = jobGetCode();

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL + '/online/jobmark',
			headers: headers,
			data: JSON.stringify({
				id: dpid,
				text: lng(messages.fireSafetyEnsured) + " " + lng(messages.telephoneNumber) + " " + phoneNumber,
				specialmark: true,
				user: {
					name: "",
					personid: ""
				}
			})
		})
			.then(function (res) {
				toggleLoading(false);

				// Close the modal and remove errors from it
				setAlertDialogProperties({
					message: '',
					type: '',
					show: false
				});
				showFireSafetyModal();

				// A successful request, continue to making the payment (redirect directly to provider or open the bank selection modal)
				makePaymentOrShowBankModal();
			})
			.catch(function (error) {
				toggleLoading(false);

				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
				} else {
					// show alert in modal without closing it
					setAlertDialogProperties({
						message: lng(messages.titleSavingFailed) + ". " + lng(messages.pleaseTryAgain),
						type: 'danger',
						show: true
					});
				}

			});
	}

	/**
	 * Opens the bank selection modal
	 */
	function showBankSelectionModal() {
		setToggleBankSelectionModal(!toggleBankSelectionModal);
	}

	/**
	 * Close the bank selection modal and move on to make the payment
	 * @param bankId
	 */
	function handleChoosePaymentMethod(bankId) {
		// set a 'forced' payment method so payment callbacks are successful when randomizing service providers
		window.sessionStorage.setItem('paymentService', paymentService.paymentService);

		// force Netaxept as service provider if a selected bank is from Netaxept
		if (bankId !== undefined && bankId !== '' && netaxeptBankIds.includes(bankId)) {
			paymentService.setPaymentService(paymentService.PAYMENTMETHODS.NETAXEPT);
			window.sessionStorage.setItem('paymentService', paymentService.PAYMENTMETHODS.NETAXEPT);
		}

		showBankSelectionModal();
		makePayment(bankId);
	}

	/**
	 * Check if bank payment selection modal should be shown in the environment.
	 * Redirect to payment service provider's bank selection modal should not be shown.
	 */
	function makePaymentOrShowBankModal() {
		const service = props.match?.params?.paymentmethod;

		// reset payment services back to environment defaults
		paymentService.resetPaymentService();

		// ensure that forced service is saved
		if (supportedServices.includes(service)) {
			window.sessionStorage.setItem('paymentService', service);
		}

		// set the payment service provider
		setServiceProvider(paymentService.getPaymentService());

		if (preselectPayment) {
			// show the bank selection modal
			showBankSelectionModal();
		} else {
			// set a 'forced' payment method so payment callbacks are successful when randomizing service providers
			window.sessionStorage.setItem('paymentService', paymentService.paymentService);
			// redirect straight to payment provider page
			makePayment();
		}
	}

	/**
	 * Creates a payment and redirects to payment service.
	 * Can receive a bank id as parameter
	 * @param bankId
	 */
	function makePayment(bankId) {
		let bankparam = '';

		// save job status, because it might affect the success message after payment
		window.sessionStorage.setItem('previousJobStatus', job.statuscode);

		// add bank id to query parameters if given
		if (bankId !== undefined && bankId !== '') {
			bankparam = '&bank_id=' + bankId;
		}

		toggleLoading(true, lng(messages.startingOnlinePayment) + "...");

		const headers = userRequestHeaders("application/pdf");
		// Add language
		headers['Accept-Language'] = mapLocaleToHeaders(userGetLocale());

		const dpid = jobGetCode() || "token";

		axios({
			method: 'GET',
			url: process.env.REACT_APP_API_URL + '/online/registerpayment/' + dpid + '?service=' + paymentService.getPaymentService() + bankparam,
			headers: headers,
			responseType: 'json',
		}).then(function (res) {
			window.location.href = res.data.url;
		}).catch(function (error) {
			toggleLoading(false);
			paymentService.resetPaymentService();
			let dialogMessage = lng(messages.pleaseTryAgain);

			if (typeof error.response !== 'undefined' && typeof error.response.data !== 'undefined') {
				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
					// job cannot be paid in Ropo Online if agency currency and job currency don't match
				} else if (error.response.data.Message === "Agency currency and job currency do not match") {
					dialogMessage = lng(messages.cannotBePaidOnline);
				}
			}

			handleAlertDialog(lng(messages.titlePaymentTerminalFailed) + ' ' + dialogMessage, 'danger');
		});
	}

	function downloadPDF(letterType) {
		if ((job.downloadpdf && letterType === 'invoice') || (job.current_letter?.downloadpdf && letterType === 'current')) {

			let requestParams = '';
			if (letterType === 'invoice') {
				toggleLoading(true, lng(messages.openingInvoice) + "...");
				requestParams = '?origtype=1&lettertype=' + letterType
			} else {
				toggleLoading(true, lng(messages.openingCurrentLetter) + "...");
				requestParams = '?origtype=0&lettertype=' + letterType + '&jobtype=' + job.current_letter?.jobtype + '&jobstatus=' + job.current_letter?.jobstatus;
				// Set disclaimer to alert about letter
				setShowAlertMessage(lng(messages.letterDisclaimer));
				setShowAlertType('warning');
				setShowAlert(true);
			}

			const headers = userRequestHeaders("application/pdf");
			axios({
				method: 'GET',
				url: process.env.REACT_APP_API_URL + '/online/pdf/' + jobGetCode() + requestParams,
				headers: headers,
				responseType: 'blob',
				params: {
					id: jobGetCode()
				}
			})
				.then(function (res) {
					// Creates an empty 'a' element that allows manipulating the file name
					let a = document.createElement("a");
					document.body.appendChild(a);
					a.style = "display: none";

					// Creates a BLOB object
					let newBlob = new Blob([res.data], {type: "application/pdf"});

					// Creates a new link
					const url = window.URL.createObjectURL(newBlob);

					a.href = url;
					a.download = "invoice_" + job.jobid + '.pdf';
					a.click();

					// Opens the link
					setTimeout(function () {
						window.URL.revokeObjectURL(url);
					}, 100);
				})
				.catch(function (error) {
					if (error.response?.status === 401) {
						const alert = {message: lng(messages.authError), type: 'danger'};
						props.history.push({
							pathname: "/logout",
							alert
						});
					} else {
						handleAlertDialog(lng(messages.titlePdfDownloadFailed) + '. ' + lng(messages.pleaseTryAgain), 'danger');
					}
				})
				.then(function () {
					toggleLoading(false);
				});
		}
	}

	/**
	 * Opens the mandate modal (authorisation)
	 */
	function showMandateModal() {
		setToggleMandateModal(!toggleMandateModal);
	}

	/**
	 *  Submits authorisation form
	 */
	function submitMandate(authorizedPerson) {
		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();
		const dpid = jobGetCode();

		axios({
			method: 'post',
			url: process.env.REACT_APP_API_URL + '/online/mandate',
			headers: headers,
			data: JSON.stringify({
				id: dpid,
				name: authorizedPerson.name,
				phone: authorizedPerson.phone,
				email: authorizedPerson.email,
				user: {
					name: "",
					personid: ""
				}
			})
		})
			.then(function (res) {
				// A successful request, gets the job's data again
				jobFetchJob(dpid).then(function (response) {
					toggleLoading(false);

					// close mandate modal and show success message in job page
					showMandateModal();
					handleAlertDialog(lng(messages.titleThankYou) + ". " + lng(messages.changesSaved), 'success');
				});
			})
			.catch(function (error) {
				toggleLoading(false);

				if (error.response?.status === 401) {
					const alert = {message: lng(messages.authError), type: 'danger'};
					props.history.push({
						pathname: "/logout",
						alert
					});
				} else {
					// show alert in mandatemodal without closing it
					setAlertDialogProperties({
						message: lng(messages.titleSavingFailed) + ". " + lng(messages.pleaseTryAgain),
						type: 'danger',
						show: true
					});
				}
			});
	}


	/**
	 * Opens the modal for creating a new message (message center modal)
	 */
	function showMessageCenterForms() {
		setToggleMessageCenterModal(!toggleMessageCenterModal);
	}


	/**
	 * Submit a new conversation from job.
	 * 
	 * @param {*} formData 
	 */
	function submitNewMessageCenterConversation(formData) {
		setClearMessageCenterModal(false);
		toggleLoading(true, lng(messages.actionSaving));

		const headers = userRequestHeaders();

		axios({
			method: 'POST',
			url: process.env.REACT_APP_API_URL + '/online/messagecenter/createticket',
			headers: headers,
			data: JSON.stringify(formData)

		}).then(function (res) {
			toggleLoading(false);
			// close message center modal and show success message in invoice page
			setClearMessageCenterModal(true);

			messagecenterRemoveMessages();

			const alert = { message: lng(messages.messageIsSent), type: 'success' };
			props.history.push({
				pathname: "/messagecenter",
				state: { fromJob: true, alert } 
			});
		}).catch(function (error) {
			toggleLoading(false);

			if (error.response?.status === 401) {
				const alert = { message: lng(messages.authError), type: 'danger' };
				props.history.push({
					pathname: "/logout",
					alert
				});
			} else {
				// show alert in MessageCenterModal without closing the modal
				setAlertDialogProperties({
					message: lng(messages.titleSavingFailed) + ". " + lng(messages.pleaseTryAgain),
					type: 'danger',
					show: true
				});
			}
		});
	}


	/**
	 * Opens wizard from step 2 (no need for user to submit search values when direct link is used.)
	 */
	function openWizardFromStep2() {
		setToggleWizard(!toggleWizard);
		setWizardStep(0)
	}


	/**
     * Handle messagecard clicks.
     *
     * @param {*} chosen
     */
    function handleMessagecard(chosen) {
        // Extract the message ID from the chosen message using the index
        const messageId = allMessages[chosen].id;
    
		props.history.push({
			pathname: "/messagecenter",
			search: `?messageId=${messageId}`,
			state: { fromJob: true} 
		});
    }

	function getTooltipContent(maintenance, showNewMessageButton, lng, messages, messageCenterAuthorized) {
		if (!messageCenterAuthorized){
			return (
				<Tooltip id='maintenance-ongoing'>
					{lng(messages.loginToSendMessage)}
				</Tooltip>
			);	
		} else if (maintenance) {
			return (
				<Tooltip id='maintenance-ongoing'>
					{ lng(messages.underMaintenance) }
				</Tooltip>
			);
		} else if (!showNewMessageButton) {
			return (
				<Tooltip id='maintenance-ongoing'>
					{ lng(messages.ongoingMessaging) }
				</Tooltip>
			);
		} else {
			return (<></>);
		}
	}

	// job page (jobs other than overpayment (302) or credit note (104))
	return (
		<>
			{!isAuthenticating && (
				<div id='job' className='content flex-fill pt-xl-4'>
					<Helmet id='job-helmet'>
						<title>{lng(messages.jobid)} {job.jobid.toString()} | MyRopo</title>
					</Helmet>
					<Container id='job-page' className={isAuthed ? '' : 'mt-4'}>
						{isAuthed && (
							<Row className='pb-3 d-none d-sm-none d-md-block ps-2 ps-sm-0'>
								<Col xs={11}>
									<Link className='btn to-jobsearch-btn text-start p-0 ps-2' to='/jobsearch' id='to-jobsearch'>
										<h5>
											<FontAwesomeIcon icon={far.faArrowLeft} /> &nbsp;
											{ lng(messages.goBack) }
										</h5>
									</Link>
								</Col>
							</Row>
						)}
						<Row className={'pb-3 m-0 ' + (isAuthed ? '' : 'pt-xl-4')}>
							<Col md={7} sm={12} xs={12}>
								<Row className='ps-2 pe-0'>
									{isAuthed && (
										<Col sm={2} xs={2} className='d-md-none'>
											<Link className='btn to-jobsearch-btn text-start p-0 ps-0' to='/jobsearch' id='to-jobsearch'>
												<h2><FontAwesomeIcon icon={far.faArrowLeft} /></h2>
											</Link>
										</Col>
									)}
									<Col xs={isAuthed ? 10 : 12} className={isAuthed ? 'ps-0 pe-3 ps-md-4 pe-sm-4' : 'ps-4 ps-sm-4'}>
										<h2 className='text-break'>{job.payee.name}</h2>
									</Col>
									{showBrowseButton && (
										<Col md={2} xs={12} className='pe-0 d-flex justify-content-center'>
											<div className={'d-flex gap-0 browse' + (firstInGovidJobs && lastInGovidJobs ? ' browse-disabled' : '')}>
												<Button className={'btn browse-job previous' + (firstInGovidJobs ? ' browse-disabled' : '')} onClick={() => getPreviousJob()} variant='browse' title={!firstInGovidJobs ? lng(messages.moveToPreviousJob) : ''}> <FontAwesomeIcon icon={far.faChevronLeft} className='me-2' /> {currentPage}</Button>
												<span>/</span>
												<Button className={'btn browse-job next' + (lastInGovidJobs ? ' browse-disabled' : '')} onClick={() => getNextJob()} variant='browse' title={!lastInGovidJobs ? lng(messages.moveToNextJob) : ''}> {lastPage} <FontAwesomeIcon icon={far.faChevronRight} className='ms-2' /></Button>
											</div>
										</Col>
									)}
								</Row>
							</Col>
							{/*General message from overpaidWizard */}
							{ typeof genericMessage.message !== 'undefined' && genericMessage.message !== '' && (
								<AlertDialog
									message={genericMessage.message}
									type={genericMessage.type}
								/>
							)}
							{/*Overpaid information*/}
							{showOverpaidInformation && (
								<AlertDialog
									message={ <div>{ lng(dashBoardMessages.overpaidInformation1) } <b>{ overpaidInformation.account }</b> { lng(dashBoardMessages.overpaidInformation2) } <b>{ overpaidInformation.name }</b>. { lng(dashBoardMessages.returnAmount) }: <b>{ overpaidInformation.amount }</b>. { lng(dashBoardMessages.refundProcessingFee) }: <b>{ overpaidInformation.fee }</b>.</div> }
									type='success'
								/>
							)}
							{/* Pay button on the first row in desktop. Payment limit can prevent users from paying the invoice in MyRopo.*/}
							{job.makepayment !== null && job.makepayment && (
								<Col md={5} className='d-none d-sm-none d-md-block d-md-flex gap-2 ps-3 pe-2 justify-content-end'>
									<Col xxl={6} xl={8} sm md lg={12} className='pe-2'>
										<OverlayTrigger
											key='show-payment-limit-exceeded'
											trigger={['hover', 'click']}
											rootClose={true}
											placement='bottom'
											overlay={disablePayButton ? (
												<Tooltip id='payment-limit-exceeded'>
													{lng(prohibited.paymentLimit)} {formatMoney(paymentlimit, userGetLocale(), currency)}
													. {lng(prohibited.paymentLimitExceeded)}
												</Tooltip>
											) : (<></>)
											}>
											<Button id='pay' className={'pay w-100 ' + (disablePayButton && 'primary-disabled')} variant="primary" onClick={disablePayButton ? undefined : () => { checkFiresafetyBeforePaying() }}  >
												{lng(messages.pay)}
											</Button>
										</OverlayTrigger>
									</Col>
								</Col>
							)}
						</Row>
						{/* Alerts and successes to show on the invoice page */}
						<Row className='ps-3 pe-4 ms-0 me-0 ps-md-0 pe-md-0'>
							{showAlert &&
								<AlertDialog
									message={showAlertMessage}
									type={showAlertType}
								/>
							}
						</Row>
						<Row>
							{job !== null && job.statuscode !== 302 && job.statuscode !== 104 && (
								<Col md={7} className='mb-2 pe-0 ps-0 ps-sm-3 pe-sm-3'>
									{job.makepayment !== null && job.makepayment && (
										<StatusInfo
											job={job}
											{...props}
										/>
									)}
									{/* //!MOBILE ONLY COMPONENTS */}
									<div id='job-mobile' className='d-md-none'>
										<InvoiceShortOverview
											job={job}
											handlePaydateModal={showNewPaydateModal}
											handlePayplanModal={showPayplanModal}
											{...props}
										/>
										<div className='d-grid gap-2 buttons-section-mobile grey-bottom-border-mobile pb-3 ps-4 pe-4 mb-3'>
											{/* Pay button in mobile. Payment limits can prevent users from paying the invoice in MyRopo.*/}
											{job.makepayment !== null && job.makepayment && (
												<OverlayTrigger
													key='show-payment-limit-exceeded'
													trigger={['hover', 'click']}
													rootClose={true}
													placement='top'
													overlay={disablePayButton ? (
														<Tooltip id='payment-limit-exceeded'>
															{lng(prohibited.paymentLimit)} {formatMoney(paymentlimit, userGetLocale(), currency)}
															. {lng(prohibited.paymentLimitExceeded)}
														</Tooltip>
													) : (<></>)
													}>
													<Button id='pay' className={'pay ms-2 me-2 ' + (disablePayButton && ' primary-disabled')} variant="primary" onClick={disablePayButton ? undefined : () => { checkFiresafetyBeforePaying() }}  >
														{lng(messages.pay)}
													</Button>
												</OverlayTrigger>
											)}
											<Button className='pb-2 ms-2 me-2' variant='tertiary' onClick={() => setShowAllInvoiceDetailsMobile(!showAllInvoiceDetailsMobile)}>
												{showAllInvoiceDetailsMobile ? lng(messages.hideInvoiceDetails) : lng(messages.showInvoiceDetails)}
											</Button>
										</div>

										{showAllInvoiceDetailsMobile && (
											<>
												<InvoiceOverview
													job={job}
													{...props}
												/>
												{job.accounts !== null && (
													<BankInformation
														job={job}
														{...props}
													/>
												)}
											</>
										)}
										<PaymentDetails
											job={job}
											mobile={true}
											{...props}
										/>
									</div>
									{/*/!* //!DESKTOP ONLY COMPONENTS*!/ */}
									<div id='job-desktop' className='d-none d-sm-none d-md-block'>
										<InvoiceOverview
											job={job}
											handlePaydateModal={showNewPaydateModal}
											handlePayplanModal={showPayplanModal}
											{...props}
										/>
										{job.accounts !== null && (
											<BankInformation
												job={job}
												{...props}
											/>
										)}
									</div>
									{job.members !== null && (
										<SubJobs
											job={job}
											{...props}
										/>
									)}
								</Col>
							)}

							<Col md={isDirectLinkOverpaid ? 8 : 5} sm={12} className='pe-0 ps-0 ps-sm-3 pe-sm-3 ps-md-1'>
								{/* Serving info shown in side column, if job is eligible for serving */}
								{showServingCard && (
									<ServingCard
										job={job}
										show={showServingCard}
										servingSigned={servingSigned}
										servingSignedDate={servingSignedDate}
										openModal={showServingModal}
										disableServingButton={disableServingButton}
										{...props}
									/>
								)}
								{/* N.B. Component and functionalities for actions added on other tickets */}
								{job !== null && (
									<Card className='fullwidth-mobile invoice-details invoice-actions grey-bottom-border-mobile margin-top-mobile ps-4 pe-4 mb-3 pt-4'>
										<h3 className='ps-2 pe-2 pe-md-0'>{ lng(messages.actions) }</h3>
										<ul className='list-unstyled ps-2 pe-2 mb-0'>
											{ showWizardButton && !overpaidInformation.show ? (
												<li>
													<span className='label'>
														<Button className='show-mandate actionlist-button' variant='tertiary' onClick={ () => openWizardFromStep2() }>
															<FontAwesomeIcon icon={ far.faMoneyCheckPen } /> &nbsp; { lng(dashBoardMessages.returnPaymentTitle) }
															<span className='float-end'><FontAwesomeIcon icon={ far.faChevronRight } /></span>
														</Button>
													</span>
												</li>
											) : ('') }
											{ job.statuscode !== 101 && job.statuscode !== 122 ? (
												<li>
													<span className='label'>
														<Button className='show-mandate actionlist-button' variant='tertiary' onClick={ () => showMandateModal() }>
															<FontAwesomeIcon icon={ far.faFileSignature } /> { lng(messages.mandate) }
															<span className='float-end'><FontAwesomeIcon icon={ far.faChevronRight } /></span>
														</Button>
													</span>
												</li>
											) : ('') }
											{/* invoice image */ }
											<li>
												<span className='label'>
													<OverlayTrigger
														key='download-pdf'
														trigger={ ['hover', 'click'] }
														overlay={
															!job.downloadpdf ? (
																<Tooltip id='pdf-cant-be-loaded' className='danger'>
																	{ lng(messages.pdfCantBeLoaded) }
																</Tooltip>
															) : (<></>)
														}>
													<Button id="download-pdf" className={ job.downloadpdf ? 'download-pdf actionlist-button' : 'download-pdf listbutton-disabled actionlist-button' } variant='tertiary'
															onClick={ () => downloadPDF('invoice') }>
														<FontAwesomeIcon icon={ far.faDownload } /> { lng(messages.invoiceImage) }
														<span className='float-end'><FontAwesomeIcon icon={ far.faChevronRight } /></span>
													</Button>
														</OverlayTrigger>
												</span>
											</li>
											{ job.current_letter?.downloadpdf ? (
												<li>
													<span className='label'>
														<Button id="download-pdf" className='download-pdf actionlist-button' variant='tertiary'
																onClick={ () => downloadPDF('current') }>
															<FontAwesomeIcon icon={ far.faDownload } /> { lng(messages.invoiceCurrentImage) }
															<span className='float-end'><FontAwesomeIcon icon={ far.faChevronRight } /></span>
														</Button>
													</span>
												</li>
											) : (<></>) }

											{/* sign serving button, visible if serving is possible for the job and is not yet signed */ }
											{ (isServingEligible && !servingSigned) ? (
												<li>
													<span className='label'>
														<OverlayTrigger
															key='serving-button'
															placement='top'
															trigger={ ['hover', 'click'] }
															overlay={
																disableServingButton ? (
																	<Tooltip id={ `serving-needs-authentication` } className='danger'>
																		{ lng(messages.strongAuthenticationNeeded) }
																	</Tooltip>
																) : (<></>)
															}
														>
															<Button id='serving-button' className={ disableServingButton ? 'sign-serving listbutton-disabled actionlist-button' : 'sign-serving actionlist-button' } variant='tertiary'
																	onClick={ () => showServingModal() }>
																<FontAwesomeIcon icon={ far.faCircleExclamation } /> &nbsp; { lng(messages.signServing) }
																<span className='float-end'><FontAwesomeIcon icon={ far.faChevronRight } /></span>
															</Button>
														</OverlayTrigger>
													</span>
												</li>
											) : ('') }
										</ul>
									</Card>
								) }
								{/* Get support */ }
								{ showSupportSection ? (
									<Card className='fullwidth-mobile invoice-details invoice-get-support-actions ps-4 pe-4 pt-sm-4 pb-sm-4'>
										<h3 className='ps-2 pe-2'>{ lng(messages.getSupport) }</h3>
										<ul className='list-unstyled ps-2 pe-2 mb-0'>
											{/* Chat-button */}
											{showChatButton ? (
												<li>
													<span className='label'>
														<ToggleChatButton visitor={{ name: userGetName(), email: '' }} parameters={livechatParameters} />
													</span>
												</li>
											) : ('')}
											{/* Open Message Center forms */}
											{showMessageCenterButton ? (
												<li>
													<span className='label'>
                                                        <OverlayTrigger
                                                            key='show-maintenance-ongoing'
                                                            trigger={ ['hover', 'click'] }
                                                            rootClose={ true }
                                                            placement='top'
                                                            overlay={getTooltipContent(props.maintenance, showNewMessageButton, lng, messages, messageCenterAuthorized)}
														>
															<Button className={props.maintenance || !showNewMessageButton || !messageCenterAuthorized ? 'show-mandate listbutton-disabled actionlist-button' : 'show-mandate actionlist-button'} variant='tertiary' onClick={() => { if (!props.maintenance && showNewMessageButton) { showMessageCenterForms(); } }}>
																<FontAwesomeIcon icon={far.faEnvelopeOpenText} /> {lng(messages.createNewMessage)}
																<span className='float-end'><FontAwesomeIcon icon={far.faChevronRight} /></span>
															</Button>
										                </OverlayTrigger>
													</span>
												</li>
											) : ('')}
										</ul>
									</Card>
								) : ('') }

								{/* Show all messages */ }
								{ !props.maintenance && messageCenterAuthorized && allMessages.length > 0 ? (
									<Card className='fullwidth-mobile invoice-details invoice-messagecenter-messages ps-3 pe-3 pt-sm-4'>
										<h3 className='ps-3 pe-3'>{ lng(messages.messageCenterMessages) }</h3>

										<div className='scrollable-content'>
											{allMessages.map((message, index) => {
												const nextIndex = index < allMessages.length - 1 ? index + 1 : '';
												const activeIndex = -1;

												// Check if message.jobid matches jobId
												if (message.jobid != job.jobid) {
													return null;
												}

												return (
													<div key={'message-' + message.id}>
														<button id={"message-" + message.id} className="btn messagecard mb-2" onClick={() => handleMessagecard(index)}>
															<MessageCard
																cardIndex={index}
																id={message.id}
																status={message.current_status.status}
																statusCode={message.jobstatus}
																dueDate={message.due_date}
																summary={message.summary}
																invoiceNumber={message.jobid}
																creditor={message.creditor}																
																activeIndex={activeIndex}
																isUnread={message.current_status.unread}
																{...props}
															/>
														</button>

														{/* do not show spacers before/after the chosen card*/}
														<hr className={'mb-2 ' + (activeIndex === index || activeIndex === nextIndex ? 'hide' : '') + " d-none d-sm-block"} />
													</div>
												);
											})}
										</div>
									</Card>
								) : ('') }
							</Col>
						</Row>
					</Container>
					{ job.modpaydate !== null && job.modpaydate ? (
						<NewPaydateModal
							handleDatepickerChange={ handleNewPaydateChange }
							handleSubmit={ submitNewPaydate }
							modpaydatemax={ job.modpaydatemax}
							modpaydatefee={job.modpaydatefee > 0 ? parseAmount(job.modpaydatefee) : ''}
							modpaydatefeeblocked={job.modpaydatefeeblocked}
							modpaydatefeetext={job.modpaydatefeetext ?? null}
							paydate={job.paydate}
							statuscode={job.statuscode}
							show={toggleNewPaydateModal}
							handlePaydateModal={showNewPaydateModal}
							showAlertDialog={alertDialogProperties}
							{...props}
						/>
					) : ('')}
					{job.dopayplan !== null && job.dopayplan ? (
						<PayplanModal
							show={togglePayplanModal}
							hide={showPayplanModal}
							updateState={updateState}
							paymentcount={state.paymentcount}
							payplanmaxlength={job.payplanmaxlength}
							handleDatepickerChange={handleFirstPaydateChange}
							paydate={job.paydate}
							payplanfee={job.payplanfee > 0 ? parseAmount(job.payplanfee) : ''}
							handleSubmit={submitPayplan}
							currency={currency}
							showAlertDialog={alertDialogProperties}
							{...props}
						/>
					) : ('')}
					<MandateModal
						show={toggleMandateModal}
						hide={showMandateModal}
						handleSubmit={submitMandate}
						alert={alertDialogProperties}
						{...props}
					/>
					{allowServing && isServingEligible ? (
						<ServingModal
							show={toggleServingModal}
							job={job}
							isServingEligible={isServingEligible}
							servingSigned={servingSigned}
							disableServingButton={disableServingButton}
							signServing={signServing}
							hide={showServingModal}
							{...props}
						/>
					) : ('')}
					{[156].includes(job.statuscode) ? (
						<FiresafetyModal
							show={toggleFiresafetyModal}
							hide={showFireSafetyModal}
							alert={alertDialogProperties}
							handleSubmit={submitFireSafety}
							{...props}
						/>
					) : ('')}
					{preselectPayment && job.makepayment !== null && job.makepayment ? (
						<BankSelectionModal
							show={toggleBankSelectionModal}
							serviceProvider={serviceProvider}
							handleBankSelectionModal={showBankSelectionModal}
							makePayment={makePayment}
							handleChoosePaymentMethod={handleChoosePaymentMethod}
							handleAlertDialog={handleAlertDialog}
							includedNetsBanks={includedNetsBanks}
							redirectToTinkForPayment={redirectToTinkForPayment}
							mainstatus={job.job_main_statuscode}
							{...props}
						/>
					) : ('')}
					{isDirectLinkOverpaid ? (
						<OverpaidWizard
							// show={showWizard}
							open={toggleWizard}
							step={wizardStep}
							job={job}
							isDirectLink={isDirectLinkOverpaid}
							overpaidInfo={handleOverpaidInfo}
							overpaidMessage={handleOverpaidMessage}
							returnPaymentStepOne={""}
							{...props}
						/>
					) : ('')}
					{messageCenterAuthorized && (
						<MessageCenterModal
							show={toggleMessageCenterModal}
							hide={showMessageCenterForms}
							handleSubmit={submitNewMessageCenterConversation}
							jobid={job.jobid}
							statuscode={job.statuscode}
							alert={alertDialogProperties}
							clearMessageCenterModal={clearMessageCenterModal}
							{...props}
						/>
					)}
				</div>

			)}
		</>
	);
};

Job.propTypes = {
	'intl': PropTypes.object.isRequired,
	'match': PropTypes.object,
	'match.params': PropTypes.object,
	'match.params.paymentmethod': PropTypes.string,
	'location': PropTypes.object,
	'location.alert': PropTypes.object,
	'history': PropTypes.any,
    'maintenance': PropTypes.bool.isRequired,
    'updateUserLogged': PropTypes.func
};

export default injectIntl(Job);